<template>
    <div>
        <div class="row mg-t-20">
            <div class="col-12">
                <div class="card bd-0">
                    <div class="card-header tx-uppercase tx-medium bd-0 tx-white bg-dark">
                        TOP TIP
                    </div><!-- card-header -->
                    <div class="card-body bd bd-t-0 tx-black bg-white rounded-bottom">
                        <p class="mg-b-0">The claims listed below are missing evidence which we require to be able to process them. Please submit this evidence as soon as possible to avoid delays or cancellations.</p>
                    </div><!-- card-body -->
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <table width="100%" class="table table-striped table-hover tx-white">
                    <thead class="thead-colored thead-primary">
                    <tr>
                        <th colspan="1" style="background: none;">
                            Awaiting Evidence
                        </th>
                        <th colspan="4" style="background: none;">
<!--                            <pagination :data="data" @search="search"></pagination>-->
                        </th>
                    </tr>
                    <tr>
                        <th>REFERENCE</th>
                        <th>TYPE</th>
                        <th>CREATED</th>
                        <th>PLATE</th>
                        <th>READY TO PROGRESS</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="d in data.claims" style="cursor: pointer">
                        <td @click="click(d.id)">{{$store.state.auth.demo ? 'REFERENCE' : d.reference}}</td>
                        <td @click="click(d.id)">
                            <span v-if="d.type === 'driveoff'">Drive Off</span>
                            <span v-if="d.type === 'escalated'">Drive Off</span>
                            <span v-if="d.type === 'nmop'">No Means of Payment</span>
                            <span v-if="d.type === 'cnaf'">Customer Not at Fault</span>
                            <span v-if="d.type === 'cnafe'">Drive Off (Customer Not at Fault)</span>
                        </td>
                        <td @click="click(d.id)" v-bind:class="{'dngr': (d.date_received !== null && d.receivedDaysAgo > 14), 'wrn': (d.date_received !== null && d.receivedDaysAgo > 7 && d.receivedDaysAgo < 15)}">{{d.date_received}} <span v-if="d.date_received !== null">({{d.receivedDaysAgo}} days ago)</span></td>
                        <td @click="click(d.id)">{{d.plate}}</td>
                        <td @click="click(d.id)">
                            <i v-if="d.markedForReady === true" class="fas fa-check tx-success"></i>
                            <i v-else class="fas fa-times tx-danger"></i>
                        </td>
                    </tr>
                    <tr v-if="data.claims.length < 1 && !loading">
                        <td colspan="5" class="text-center">No Claims</td>
                    </tr>
                    <tr v-if="loading">
                        <td colspan="5" class="text-center">Loading Claims, please wait...</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import axios from 'axios';
import authHeader from '@/services/auth-header';

export default {
    name: "Evidence",
    components: {Pagination},
    data(){
        return {
            data: {
                claims: [],
                currPage: 1,
                totalResults: 0,
                from: 0,
                to: 0,
                perPage: 0,
                lastPage: 1,
            },
            loading: true,
        }
    },
    mounted() {
        this.search(1);
    },
    methods: {
        search(page){
            axios.get(`https://server.varsanpr.com/v2/claims/evidence?client_id=${this.$store.state.auth.user.selectedClient}`, {
                headers: authHeader()
            })
                .then(response => {
                    this.data.claims = response.data.claims;
                    this.loading = false;
                })
                .catch(error => {
                    this.$error("Failed fetching claims.", error);
                    console.error(error);
                    this.loading = false;
                });
        },
        click(id){
            this.$router.push(`/evidence/${id}`);
        }
    }
}
</script>

<style scoped>
    .dngr {
        color: #DC3545 !important;
    }
    .wrn {
        color: #F49917 !important;
    }
</style>